import { socials } from '../_style/socials';

export const SocialNetworksType: string[] = ['facebook', 'linkedin', 'twitter', 'ok.ru'];

export const ContactsType: string[] = ['phone', 'email', 'whatsapp', 'telegram'];

export const socialLogos: { [key: string]: string } = {
  phone: socials.phone,
  email: socials.email,
  messenger: socials.messenger,
  skype: socials.skype,
  telegram: socials.telegram,
  viber: socials.viber,
  facebook: socials.facebook,
  instagram: socials.instagram,
  whatsapp: socials.whatsapp,
  linkedin: socials.linkedin,
  twitter: socials.twitter,
  'ok.ru': socials.ok,
};
