import React from 'react';
import { createStore, combineReducers } from 'redux';
import { historyReducer } from './history/reducers';
import { userReducer, UserState } from './user/reducers';
import { sitesReducer, ListSitesObjectData } from './sites/reducers';
import { storageReducer, ListStorageObjectData } from './storage/reducers';

/**
 *
 */
const rootReducer = combineReducers({
  history: historyReducer,
  user: userReducer,
  sites: sitesReducer,
  storage: storageReducer,
});

/**
 * Export store
 */
const store = createStore(rootReducer);

/**
 *
 */
export interface StoreType {
  history: React.ReactNode[];
  user: UserState;
  sites: ListSitesObjectData;
  storage: ListStorageObjectData;
}

/**
 *
 */
export default store;
