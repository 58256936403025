import { BrowserRouter } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store, { StoreType } from './_store';
import Routes from './Routes';
// import './style.css';
import { HeaderUI, MainMenu } from './UI';
import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import { API } from './_helper';
import { UserState } from './_store/user/reducers';
import { Col, ConfigProvider, Layout, Row, Spin } from 'antd';
// import Start from './pages/Start';

import en from 'antd/lib/locale/en_US';
import ru from 'antd/lib/locale/ru_RU';
import he from 'antd/lib/locale/he_IL';

import './_style/style.less';
import { Locale } from 'antd/lib/locale-provider';
import { useTranslation } from 'react-i18next';
import ApiCalendar from 'react-google-calendar-api';

const { Header, Sider, Content } = Layout;

/**
 * APP Component
 */
const App = () => {
  //
  const [cookies] = useCookies(['token']);

  //
  // const [collapsed, setCollapsed] = useState(false);

  // считываем из redux
  const user = useSelector((state: StoreType) => state.user);
  //
  const dispatch = useDispatch();

  //
  const locales: { [key: string]: Locale } = {
    ru,
    en,
    he,
  };
  //
  const { i18n } = useTranslation();

  //
  const [profile, setProfile] = useState<UserState | null>();

  if (cookies.token) {
    localStorage.setItem('token', cookies.token);
  } else {
    // window.location.href = process.env.REACT_APP_CURRENT_DOMAIN + '/login';
    window.location.href = process.env.REACT_APP_CURRENT_DOMAIN + '/';
  }

  // GET request
  const getProfile = async () => {
    const resp: any = await API('account');
    if (resp?.id) {
      setProfile({
        ...resp,
        firstName: resp.first_name,
        lastName: resp.last_name,
      });
    } else {
      // window.location.href = process.env.REACT_APP_CURRENT_DOMAIN + '/login';
      window.location.href = process.env.REACT_APP_CURRENT_DOMAIN + '/';
    }
  };

  //
  useEffect(() => {
    ApiCalendar.onLoad(() => {
      // console.log(ApiCalendar);
      // setAuthorized(ApiCalendar.sign);
      console.log('onLoad  googleAccess', ApiCalendar.sign);
      dispatch({
        type: 'SET_USER',
        payload: {
          googleAccess: ApiCalendar.sign,
        },
      });
      ApiCalendar.listenSign((googleAccess: boolean) => {
        console.log('listenSign  googleAccess', googleAccess);
        // setAuthorized(auth);
        dispatch({
          type: 'SET_USER',
          payload: {
            googleAccess,
          },
        });
      });
    });

    if (!profile) {
      getProfile();
    }
  }, []);

  /** */
  return (
    <>
      {!!profile && (
        <BrowserRouter>
          <ConfigProvider
            direction={i18n.language === 'he' ? 'rtl' : 'ltr'}
            locale={locales[i18n.language]}
          >
            <Layout hasSider={window.outerWidth > 575} style={{ minHeight: '100vh' }}>
              {window.outerWidth > 575 && (
                <Sider
                  width={250}
                  breakpoint="sm"
                  style={{
                    maxHeight: '100vh',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    borderRight: '1px solid #E1E6F5',
                  }}
                  theme={'dark'}
                  collapsible={window.outerWidth > 990}
                  collapsedWidth={64}
                  onCollapse={(value) =>
                    dispatch({
                      type: 'SET_USER',
                      payload: {
                        collapsedMenu: value,
                      },
                    })
                  }
                  collapsed={window.outerWidth <= 991 ? true : user.collapsedMenu}
                >
                  <MainMenu collapsed={window.outerWidth <= 991 ? true : user.collapsedMenu} />
                </Sider>
              )}

              <Layout>
                <Header
                  style={{
                    backgroundColor: 'white',
                    padding:
                      window.outerWidth > 991
                        ? '0 40px'
                        : window.outerWidth > 575
                        ? '0 25px'
                        : '0 20px',
                  }}
                >
                  <HeaderUI />
                </Header>
                <Content style={{ position: 'relative' }}>
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      padding:
                        window.outerWidth > 991
                          ? '30px 40px'
                          : window.outerWidth > 575
                          ? '24px'
                          : '15px',
                      left: 0,
                      top: 0,
                      overflowY: 'auto',
                      overflowX: 'hidden',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {/* {profile.tariffId < 0 ? (                        
                        <Start profile={profile} setProfile={setProfile} />
                      ) : (
                        <Routes profile={profile} />
                      )} */}
                    <Routes profile={profile} />
                  </div>
                </Content>
              </Layout>
            </Layout>
          </ConfigProvider>
        </BrowserRouter>
      )}
      {!profile && (
        <Row align="middle" justify="center" style={{ minHeight: '100vh' }}>
          <Col>
            <Spin size="large" />
          </Col>
        </Row>
      )}
    </>
  );
};

export default App;
