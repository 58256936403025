const ru = {
  translation: {
    left_0: 'Остался',
    left_1: 'Осталось',
    left_2: 'Осталось',
    days_0: 'день',
    days_1: 'дня',
    days_2: 'деней',

    Login: 'Войти',
    Entry: 'Вход',
    phone: 'телефон',
    'Last Name': 'Фамилия',
    Edit: 'Редактировать',
    Password: 'пароль',
    'Write a message': 'Написать сообщение',
    'Select category': 'Выберите категорию',
    'Select a tariff': 'Выберите тариф',
    Tariffs: 'Тарифы',
    'Change tariff': 'Изменить тариф',
    Collapse: 'Свернуть',
    'To publish': 'Опубликовать',
    'Leave in drafts': 'Сохранить в черновик',
    'View tariff comparison': 'Смотреть сравнение тарифов',
    'Additional services': 'Дополнительные услуги',
    'Extend tariff': 'Продлить тариф',
    'Referral link': 'Реф. ссылка',
    Profile: 'Профиль',
    'Payment history': 'История оплаты',
    'Payment information': 'Платежная информация',
    'Other users': 'Другие пользователи',
    'Infobbook Users': 'Пользователи Infobbook',
    Promotion: 'Реклама',
    'How it works': 'Как это работает',
    'Edit account': 'Редактировать аккаунт',
    'Delete photo': 'Удалить фото',
    Name: 'Имя',
    'Last name': 'Фамилия',
    Phone: 'Телефон',
    'About myself': 'О себе',
    Add: 'Добавить',
    'Order the creation of a landing page': 'Заказать создание лендинга',
    Basic: 'Основное',
    'Choosing a template': 'Выбор шаблона',
    Domain: 'Домен',
    Continue: 'Продолжить',
    Cancel: 'Отмена',
    Skip: 'Пропустить',
    Check: 'Проверить',
    Back: 'Назад',
    'Other domain zones': 'Другие доменные зоны',
    'My webinars': 'Мои вебинары',
    'Create a webinar': 'Создать вебинар',
    Send: 'Отправить',
    'Overall balance': 'Общий баланс',
    'Awaiting withdrawal': 'Ожидают вывода',
    'Withdraw funds': 'Вывести средства',
    'Fill out the profile': 'Заполните профиль',
    month: 'месяц',
    year: 'год',
    Save: 'Сохранить',
    'Request a call back': 'Заказать обратный звонок',
    Logout: 'Выйти',
    Expires: 'Истекает',
    Visits: 'Показов',
    'from referrals': 'от рефералов',
    Applications: 'Заявок',
    Conversion: 'Конверсия',
    'your mentor': 'ваш ментор',
    'My leads': 'Мои лиды',
    'All leads': 'Все лиды',
    'My progress': 'Мои достижения',
    'Consultation with a mentor': 'Консультация с ментором',
    'Help me with promotion': 'Помочь мне с продвижением',
    'How to promote my business?': 'Как продвигать бизнес?',
    date: 'дата',
    client: 'клиент',
    Source: 'Источник',
    Status: 'Статус',
    'Not available': 'Недоступен',
    'Send CP': 'Отправить КП',
    'Call back later': 'Перезвонить позже',
    New: 'Новый',
    'Add page': 'Cоздать страницу',
    'To the help center': 'В справочный центр',
    'Сhange tariff': 'Изменить тариф',
    Tariff: 'Тариф',
    'Add chat': 'Добавить чат',
    'Cancel participate': 'Отменить участие',

    'Create payment': 'Пополнить баланс',
    'Please input your amount!': 'Пожалуйста, введите сумму!',
    'the top-up amount': 'cумма пополнения',
    'Payment made successful': 'Платеж выполнен успешно',
    'Current payment method': 'Текущий способ оплаты',
    'Set as default payment': 'Сделать по умолчанию',
    'Add card': 'Добавить карту',
    'Add payment card': 'Добавить платежную карту',

    'I need help': 'Мне нужна помощь',
    'Open the page': 'Открыть страницу',
    'Domain name': 'Доменное имя',
    'Domain is busy': 'Домен занят',
    'Enter your desired domain name': 'Введите желаемое доменное имя',
    Buy: 'Купить',
    'Is this your domain?': 'Это ваш домен?',
    'Domain connection': 'Подключение домена',
    'Replenishment of the balance': 'Пополнение баланса',
    'Transfer money': 'Перевести деньги',
    Refill: 'Пополнение счета',
    Withdrawals: 'Вывод средств',
    amount: 'Сумма',
    operation: 'Операции',
    'Pre year': 'За год',
    'Pre month': 'За месяц',
    'Enter your message': 'Введите сообщение',
    applay_new_pages: 'Нужна помощь с созданием лендинга?',
    applay_new_pages_text:
      'Сделаем лендинг под ключ, настроим рекламу — вам останется только принимать заявки клиентов',
    'Business pages': 'Бизнес-страницы',
    Contacts: 'Контакты',
    Conferences: 'Конференции',
    'Business instruments': 'Бизнес-инструменты',
    'Business calendar': 'Бизнес-календарь',
    Finances: 'Финансы',
    Charity: 'Благотворительность',
    'In progress': 'В обработке',
    Processed: 'Обработан',
    'Add funds': 'Пополнить счет',
    Activate: 'Активировать',
    'Add lead': 'Добавить контакт',
    'Business types': 'Вид деятельности',
    'Upload files': 'Загрузить файлы',
    'Application accepted': 'Заявка принята',
    'Help to create a landing page': 'Помощь по созданию лендинга',
    'Request a call back and we will contact you shortly':
      'Закажите обратный звонок и мы свяжемся с вами в ближайшее время',
    'To the main': 'На главную',
    'The page you are looking for may have been removed, changed, or temporarily unavailable':
      'Страница, которую вы ищете, могла быть удалена, изменена или временно недоступна',
    'Activate tariff': 'Активировать тариф {{tariff}}',
    When_you_switch: 'При переходе на "{{tariff}}" с вашего счета будет списано {{sum}}',
    'Add webinar': 'Добавить конференцию',
    'Come on': 'Начать конференцию',
    'Site Title': 'Название сайта',
    Subdomain: 'Поддомен',
    Language: 'Язык',
    'File name': 'Название файла',
    Date: 'Время',
    Size: 'Размер',
    Action: 'Действие',
    'Are you shure?': 'Вы уверены?',
    Yes: 'Да',
    Ok: 'Да',
    No: 'Нет',
    'Are you sure?': 'Вы уверены?',
    'input search text': 'введите текст для поиска',
    Search: 'Поиск',
    share: 'поделиться',
    delete: 'удалить',
    Delete: 'Удалить',
    'Free place': 'Свободное место',
    available_traffic:
      'Доступно {{traffic}} из 1 Гб. Выберите другой тариф, чтобы расширить хранилище',
    'Click or drag file to this area to upload':
      'Щелкните или перетащите файл в эту область, чтобы загрузить',
    'file actions': 'действия с файлом',
    'You have not added any files yet': 'У вас ещё не добавлено ни одного файла',
    Canceled: 'Отменено',
    'In the process': 'В процессе',
    Completed: 'Завершено',
    'File deleted successfully': 'Файл успешно удален',
    'Profile saved successfully!': 'Профиль успешно сохранен!',
    'Default payment method was changed successful': 'Способ оплаты по умолчанию изменен успешно',
    'Card deleted successful': 'Карта удалена успешно',
    'Please select': 'Пожалуйста выберите',
    'Tell us about yourself and your business': 'Расскажите о себе и своем бизнесе',
    'Refferal links': 'Реферальные ссылки',
    'Open profile': 'Открытый профиль',
    Homepage: 'Главная страница',
    'Public profile': 'Публичный профиль',
    'Social networks': 'Соц.сети',
    'Your card details are securely protected': 'Данные ваших карт надежно защищены',
    'Back Home': 'Вернуться на главную',
    'all questions': 'все вопросы',
    'hide questions': 'скрыть вопросы',
    'Enter a code': 'Введите код',
    'A message with a confirmation code has been sent to your number':
      'На ваш номер было выслано сообщение с кодом подтверждения',
    'SMS code': 'SMS код',
    'Did not receive SMS?': 'Не получили SMS?',
    'Send again': 'Отправить сново',
    'Send sode': 'Отправить код',
    'Invalid code entered': 'Введен неверный код',
    'Code sent successfully': 'Код успешно отправлен',
    'SMS sent successfully': 'SMS успешно отправлено',
    'Enter a comment for the status': 'Введите комментарий к статусу',
    'Enter the link to the social network': 'Введите ссылку на соцсеть',
    'Social network': 'Соц.сети',
    Publish: 'Опубликовать',
    Messages: 'Сообщения',
    Create: 'Создать',
    'Enter folder name': 'Введите название папки',
    'Create folder': 'Создать папку',
    Documents: 'Документы',
    Videos: 'Видео',
    Pictures: 'Изображения',
    'Create subfolders': 'Создать вложенные папки',
    'New Page': 'Новая страница',
    'Social link save successfully!': 'Социальная ссылка успешно сохранена!',
    Preview: 'Предпросмотр',
    Select: 'Выбрать',
    Message: 'Сообщение',
    Participate: 'Принять участие',
    'Displayed in the name of the browser tab and in the search engine results.':
      'Отображается в названии вкладки браузера и в результатах поисковых систем',
    'This is a free domain. You can connect your domain or change it at any time.':
      'Это бесплатный домен. Вы можете подключить свой домен или изменить его в любой момент.',
    'Translation of buttons, pop-ups into the language of the site audience':
      'Перевод кнопок,  всплывающих окон  на язык аудитории сайта',
    'webinar title': 'название вебинара',
    'Choose a time': 'Выберите время проведения',
    'Draft saved successfully!': 'Черновик успешно сохранен!',
    'Choose a ready-made template for your website': 'Выберите готовый шаблон для вашего сайта',
    'Need help adding a domain?': 'Нужна помощь с добавлением домена?',
    'Our consultants will help you set up your domain':
      'Наши консультанты помогут настроить ваш домен',
    'Save to draft or publish?': 'Сохранить в черновик или опубликовать?',
    you_need_to_change:
      'Для подключения домена {{internal}}.biz нужно изменить DNS серверы на следующие значения:',
    'DNS records are changed in the domain control panel. After adding the record, the domain should work within 24 hours.':
      'DNS записи изменяются в панели управления доменом. После добавления записи домен должен заработать в течение суток.',
    'Read instructions for connecting a domain': 'Читать инструкцию по подключению домена',
    'Page published successfully': 'Страница успешно опубликована',
    'Page saved successfully': 'Страница успешно сохранена',
    'Free subdomain is available to you': 'Вам доступен бесплатный поддомен',
    'you can edit the address on the "Basic" tab.':
      'отредактировать адрес можно на вкладке “Основное".',
    'Edit address': 'Отредактировать адрес',
    'You can use an existing domain or register a new one':
      'Вы можете использовать существующий домен или зарегистрировать новый',
    'Domain connection is not available on a free plan':
      'Подключение домена недоступно на бесплатном тарифе',
    'Change your tariff plan to connect your domain':
      'Поменяйте тарифный план, чтобы подключить домен',
    'You have not created any pages yet': 'У вас ещё не создано ни одной страницы',
    'Your page will be visible to other users': 'Ваша страница будет видна другим пользователям',
    'Edit profile': 'Редактировать профиль',
    'Video-Avatar': 'видео-аватар',
    Video: 'Видео',
    Avatar: 'аватар',
    link: 'Ссылка',
    download: 'Загрузить',
    'Link to video': 'Ссылка на видео',
    'Public business pages': 'Публичные бизнес-страницы',
    'My contacts': 'Мои контакты',
    'My social networks': 'Мои соц.сети',
    'Add your contacts': 'Добавьте свои контакты',
    'Add your social networks': 'Добавьте свои социальные сети',
    'Minimum one social link should be filled':
      'Должна быть заполнена как минимум одна социальная ссылка',
    'Phone is required': 'Телефон обязателен',
    'WhatsApp or Telegram are required': 'Требуются WhatsApp или Telegram',
    'file uploaded successfully': 'файл успешно загружен',
    'file upload failed': 'загрузка файла не удалась',
    'Uploaded video file': 'Загруженный видео файл',
    'email is already busy': 'электронная почта уже занята',
    'My profile': 'Мой профиль',
    'Complete your profile to get started use the portal':
      'Заполните профиль, чтобы начать пользоваться порталом',
    'The portal will be active and accessible for you at the end of the profile editing.':
      'Портал будет для вас активными и доступным по окончанию редакции профиля.',
    'Attach video': 'Прикрепить видео',
    'You can provide a link to a Youtube video, Vimeo':
      'Вы можете указать ссылку на видео Youtube, Vimeo',
    'Kind of activity': 'Вид деятельности',
    'One-time Lifetime Payment!': 'Единоразовый пожизненный платеж!',
    '30-day money-back guarantee': '30-дневная гарантия возврата средств',
    Purchase: 'Оплатить',
    'at a discount': 'по скидке',
    'in year': 'в год',

    'Deactivate account': 'Отключить учетную запись',
    Deactivate: 'Отключить',
    'Confirm deactiovation': 'Подтверждаю деактивацию',

    'Subscription payment': 'Оплата абонемента',
    'Forewer subscription fee': 'Одноразовый пожизненный платеж',
    'Contact Information': 'Контактная информация',
    'Payment Information': 'Платежная информация',
    'Cardholder Name': 'Владелец карты',
    'Video-Avatar should be less 65Mb': 'Видео-аватар должен быть менее 65Мб',
    'Increase text up to 1200 symbols': 'Увеличить до 1200 символов',
    Referrals: 'Рефералы',
    'Show by': 'На странице',
    Country: 'Страна',
    Comment: 'Комментарии',
    Summa: 'Сумма',

    Chat: 'Чат',

    'To use Business calendar we need authorize you at Google Calendar':
      'Для использования Бизнес-календаря разрешите доступ InfoBBook к Вашему Google Календарю',

    'Give access': 'Предоставить доступ',
    Reminders: 'Уведомления',
    'By Email': 'По Email',
    Notification: 'Уведомление',
    'Start date': 'Дату начала',
    'Start time': 'Время начала',
    'Event title': 'Заголовок события',
    minutes: 'мин.',
    hours: 'ч.',
    days: 'д.',
    weeks: 'нед.',
    'New event': 'Новое событие',
  },
};
export default ru;
