import { storageActionsType } from './actions';
import { StateType } from 'typesafe-actions';

/**
 * Данные по тарифам
 */

export interface StorageData {
  name: string;
  size: number;
  lastModified: number;
  base64: string;
}

/**
 *
 */
export interface ListStorageObjectData {
  [key: number]: StorageData;
}

/**
 * Initial state
 */
const INITIAL_STORAGE_STATE: ListStorageObjectData = {};

/**
 * Root reducer
 *
 * @param state prev state
 * @param action current action
 */
export function storageReducer(storage = INITIAL_STORAGE_STATE, action: storageActionsType) {
  switch (action.type) {
    case 'SET_STORAGE':
      return { ...storage, ...action.payload };
    case 'CLEAR_STORAGE':
      return INITIAL_STORAGE_STATE;
    default:
      return storage;
  }
}

export type storageReducerType = StateType<typeof storageReducer>;
