import { sitesActionsType } from './actions';
import { StateType } from 'typesafe-actions';

/**
 * Данные по тарифам
 */

export interface SiteData {
  name?: string;
  adress?: string;
  favicon?: string;
  desc?: string;
  keywords?: string[];
  seo?: boolean;
  branding?: boolean;
}

/**
 *
 */
export interface ListSitesObjectData {
  [key: number]: SiteData;
}

/**
 * Initial state
 */
const INITIAL_SITES_STATE: ListSitesObjectData = {};

/**
 * Root reducer
 *
 * @param state prev state
 * @param action current action
 */
export function sitesReducer(sites = INITIAL_SITES_STATE, action: sitesActionsType) {
  switch (action.type) {
    case 'SET_SITES':
      return { ...sites, ...action.payload };
    case 'CLEAR_SITES':
      return INITIAL_SITES_STATE;
    default:
      return sites;
  }
}

export type sitesReducerType = StateType<typeof sitesReducer>;
