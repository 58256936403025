import { Button, Col, Input, Popover, Row, Space, Tag } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined } from '@ant-design/icons';

export const statuses = [
  { value: 1, title: 'New' },
  { value: 9, title: 'In progress' },
  { value: 10, title: 'Processed' },
];

interface LeadStatusProps {
  id: number;
  status: number;
  comment: string;
  changeStatus: (id: number, status: number, comment: string) => void;
}

const LeadStatus: React.FC<LeadStatusProps> = ({ id, status, comment, changeStatus }) => {
  //
  const { t } = useTranslation();

  //
  const [visible, setVisible] = useState(false);
  const [newStatus, setNewStatus] = useState(status);
  const [newComment, setNewComment] = useState(comment);

  //
  const statusObject = (value: number) => {
    let color = '';
    let text = '';
    switch (value) {
      case 10:
        text = 'Confirmed';
        color = '#B7002C';
        break;
      case 9:
        text = 'In progress';
        color = '#1B9B27';
        break;
      case 1:
        text = 'New';
        color = '#1D2DBD';
        break;
    }
    return {
      text,
      color,
    };
  };

  //
  useEffect(() => {
    if (!visible) {
      setNewStatus(status);
      setNewComment(comment);
    }
  }, [visible]);

  //
  useEffect(() => {
    if (newStatus !== status) {
      setNewComment('');
    } else {
      setNewComment(comment);
    }
  }, [newStatus]);

  return (
    <Popover
      title={
        <Row gutter={16}>
          {statuses.map((item) => (
            <Col>
              <Button
                key={item.value}
                type="link"
                onClick={() => setNewStatus(item.value)}
                size="small"
                // style={{ padding: 0 }}
                style={{ marginBottom: 10, padding: 0, marginTop: 5 }}
              >
                <Tag
                  color={item.value === newStatus ? statusObject(item.value).color : 'white'}
                  icon={item.value === newStatus ? <CheckCircleOutlined /> : null}
                  key={item.value}
                  style={{
                    margin: 0,
                    minWidth: 110,
                    lineHeight: '26px',
                    color: item.value === newStatus ? 'white' : statusObject(item.value).color,
                    border: '1px solid ' + statusObject(item.value).color,
                  }}
                >
                  {t(item.title)}
                </Tag>
              </Button>
            </Col>
          ))}
        </Row>
      }
      trigger="click"
      content={
        <Space direction="vertical" style={{ width: '100%' }}>
          <Input.TextArea
            placeholder={t('Enter a comment for the status')}
            onChange={(e) => setNewComment(e.target.value)}
            value={newComment}
          />
          <Row justify="space-between">
            <Button type="primary" ghost size="small" onClick={() => setVisible(false)}>
              {t('Cancel')}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                // if (status !== newStatus) {
                changeStatus(id, newStatus, newComment);
                // }
                setVisible(false);
              }}
              size="small"
            >
              {t('Save')}
            </Button>
          </Row>
        </Space>
      }
      visible={visible}
      onVisibleChange={(newVisible) => setVisible(newVisible)}
    >
      <Button type="link" style={{ padding: 0 }}>
        <Tag style={{ lineHeight: '26px', minWidth: 90 }} color={statusObject(status).color}>
          {t(statusObject(status).text)}
        </Tag>
      </Button>
    </Popover>
  );
};

export default LeadStatus;
