import { Image, Popover, Row } from 'antd';
import curRub from '../../_style/ico/currency_rub.svg';
import curUsd from '../../_style/ico/currency_usd.svg';
import curIls from '../../_style/ico/currency_ils.svg';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

// select languages
const currences = [
  {
    value: 'RUB',
    ico: curRub,
  },
  {
    value: 'USD',
    ico: curUsd,
  },
  {
    value: 'ILS',
    ico: curIls,
  },
];

//
const langImageStyle = {
  borderRadius: '50%',
  width: 24,
  height: 24,
  // border: '1px solid #E1E6F5',
};

const CurrencySelector = () => {
  //
  const { i18n } = useTranslation();

  //
  const [opened, setOpened] = useState(false);

  //
  const [current, setCurrent] = useState('RUB');

  return (
    <Popover
      trigger="click"
      placement="bottom"
      onVisibleChange={(value) => {
        setOpened(value);
      }}
      visible={opened}
      content={
        <>
          {currences
            .filter((c) => c.value !== current)
            .map((item, key) => (
              <Row
                key={key}
                align="middle"
                onClick={() => {
                  setOpened((state) => !state);
                  setCurrent(item.value);
                }}
                style={{ paddingTop: key !== 0 ? 15 : 0, cursor: 'pointer' }}
              >
                <Image
                  src={item.ico}
                  preview={false}
                  alt={`large${item.value}`}
                  style={langImageStyle}
                />
                <span style={{ padding: '0 8px' }}>{item.value}</span>
              </Row>
            ))}
        </>
      }
    >
      <Row align="middle" style={{ cursor: 'pointer', padding: 5 }}>
        <Image
          src={currences.find((item) => item.value === current)?.ico}
          preview={false}
          alt={'largeIcon'}
          style={langImageStyle}
        />

        <span style={{ padding: '0 8px' }}>{current}</span>
        <svg
          style={{
            transition: 'all 0.2s',
            transform: opened ? 'scaleY(-1)' : 'scaleY(1)',
          }}
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.12508 1.00002L6.00508 4.88002L9.88508 1.00002C10.2751 0.610016 10.9051 0.610016 11.2951 1.00002C11.6851 1.39002 11.6851 2.02002 11.2951 2.41002L6.70508 7.00002C6.31508 7.39002 5.68508 7.39002 5.29508 7.00002L0.705083 2.41002C0.517831 2.22318 0.412598 1.96953 0.412598 1.70502C0.412598 1.4405 0.517831 1.18685 0.705083 1.00002C1.09508 0.620016 1.73508 0.610016 2.12508 1.00002Z"
            fill={opened ? '#b7002c' : '#C3CAD9'}
          />
        </svg>
      </Row>
    </Popover>
  );
};

export default CurrencySelector;
