import { Button, Col, Input, Popover, Row, Space, Tag, Tooltip, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import pencilIcon from '../../_style/ico/pensil_grey.svg';

interface ContactStatusProps {
  id: number;
  comment: string;
  changeStatus: (id: number, comment: string) => void;
}

const ContactStatus: React.FC<ContactStatusProps> = ({ id, comment, changeStatus }) => {
  //
  const { t } = useTranslation();

  //
  const [visible, setVisible] = useState(false);
  const [newComment, setNewComment] = useState(comment);

  //
  useEffect(() => {
    if (!visible) {
      setNewComment(comment);
    }
  }, [visible]);

  return (
    <Popover
      trigger="click"
      content={
        <Space direction="vertical" style={{ width: '100%' }}>
          <Input.TextArea
            placeholder={t('Enter a comment for the status')}
            onChange={(e) => setNewComment(e.target.value)}
            value={newComment}
          />
          <Row justify="space-between">
            <Button type="primary" ghost size="small" onClick={() => setVisible(false)}>
              {t('Cancel')}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                // if (status !== newStatus) {
                changeStatus(id, newComment);
                // }
                setVisible(false);
              }}
              size="small"
            >
              {t('Save')}
            </Button>
          </Row>
        </Space>
      }
      visible={visible}
      onVisibleChange={(newVisible) => setVisible(newVisible)}
    >
      <Space style={{ justifyContent: 'center', margin: '0 -14px' }}>
        <div style={{ width: 4 }} />
        <Typography.Paragraph style={{ width: 170, margin: 0 }} ellipsis>
          {comment}
        </Typography.Paragraph>
        <Tooltip title={t('Edit comment')}>
          <Button
            ghost
            icon={<img src={pencilIcon} className="anticon" />}
            style={{ border: 'none', boxShadow: 'none' }}
          />
        </Tooltip>
      </Space>
    </Popover>
  );
};

export default ContactStatus;
