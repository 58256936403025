import { Switch, Route, Redirect } from 'react-router';
import { useDispatch } from 'react-redux';
import { UserState } from './_store/user/reducers';
import { lazy, Suspense, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SocialNetworksType } from './_helper/socials';

const ProfileRout = lazy(() => import('./pages/ProfilePage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const WebinarsPage = lazy(() => import('./pages/WebinarsPage'));
const FilesRout = lazy(() => import('./pages/FilesPage'));
const UsersPage = lazy(() => import('./pages/UsersPage'));
const UserPage = lazy(() => import('./pages/UserPage'));
const TariffsPage = lazy(() => import('./pages/TariffsPage'));
const LeadesPage = lazy(() => import('./pages/LeadesPage'));
const PromoPage = lazy(() => import('./pages/PromoPage'));
const HowItWorksPage = lazy(() => import('./pages/HowItWorksPage'));
const PagesPage = lazy(() => import('./pages/PagesPage'));
const NewPage = lazy(() => import('./pages/NewPage'));
const ChatPage = lazy(() => import('./pages/ChatPage'));
const Telegram = lazy(() => import('./pages/Telegram'));
const CharityPage = lazy(() => import('./pages/CharityPage'));
const CalendarPage = lazy(() => import('./pages/CalendarPage'));

/**
 *
 */
const Routes: React.FC<{ profile: UserState }> = ({ profile }) => {
  //
  const dispatch = useDispatch();
  //
  const history = useHistory();

  //
  useEffect(() => {
    if (profile && !profile.subscription) {
      history.replace('/users');
    }

    if (profile && profile.contacts.length === 0) {
      // history.replace('/profile');
    }

    dispatch({
      type: 'SET_USER',
      payload:
        profile.contacts.length === 0
          ? {
              ...profile,
              contacts: [
                { name: 'phone', link: profile.phone, publish: true },
                { name: 'email', link: '', publish: false },
                { name: 'whatsapp', link: profile.phone, publish: false },
                { name: 'telegram', link: profile.phone, publish: false },
              ],
              socials: SocialNetworksType.map((name) => ({
                name,
                link: '',
                publish: false,
              })),
            }
          : profile,
    });
  }, []);

  /**/
  return (
    <Suspense fallback={null}>
      <Switch>
        <Route exact path="/" component={PagesPage} />
        <Route path="/pages/new-page" component={NewPage} />
        <Route path="/pages/:pageId" component={NewPage} />
        <Route path="/new-page" component={NewPage} />
        <Route path="/webinars" component={WebinarsPage} />
        <Route path="/files" component={FilesRout} />
        <Route exact path="/users" component={UsersPage} />
        <Route path="/users/:userId" component={UserPage} />
        <Route path="/tariffs" component={TariffsPage} />
        <Route path="/leads" component={LeadesPage} />
        <Route path="/promo" component={PromoPage} />
        <Route path="/how-it-works" component={HowItWorksPage} />
        <Route path="/profile" component={ProfileRout} />
        <Route exact path="/chat" component={Telegram} />
        {/* <Route path="/chat/:userId" component={ChatPage} /> */}
        <Route path="/charity" component={CharityPage} />
        <Route path="/calendar" component={CalendarPage} />
        <Route render={() => <NotFoundPage />} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
