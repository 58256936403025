import { Button, Col, Form, Input, Row, Space } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import input_cancel from '../../_style/ico/input-cancel.svg';
import input_save from '../../_style/ico/input-save.svg';
import pensil_grey from '../../_style/ico/pensil_grey.svg';
import trash from '../../_style/ico/delete.svg';
import visible from '../../_style/ico/visible.svg';
import unvisible from '../../_style/ico/unvisible.svg';
import { FormInstance, Rule } from 'antd/lib/form';
import classNames from 'classnames';
import { TypeSocialLink } from '../../_store/user/reducers';
import PhoneInput from 'react-phone-input-2';
import { socialLogos } from '../../_helper/socials';

interface SocialProps {
  type: 'contacts' | 'socials';
  name: number;
  fieldKey: number;
  handleDelete: (index: number | number[]) => void;

  social: TypeSocialLink;

  form: FormInstance<any>;
}

const UserSocialLink: React.FC<SocialProps> = ({
  name,
  type,
  fieldKey,
  handleDelete,
  social,
  form,
}) => {
  //
  const { t } = useTranslation();

  //
  const [editable, setEditable] = useState(false);
  const [published, setPublished] = useState(social.publish);
  const [value, setValue] = useState(social.link);

  //
  const changePlaceholder = () => {
    switch (social.name) {
      case 'phone':
        return 'Enter phone number';
      case 'email':
        return 'Enter email';
      default:
        return 'Enter the link to the social network';
    }
  };

  //
  const restoreValue = () => {
    setEditable(false);

    setValue(social.link);
  };

  //
  const saveValue = (value: string) => {
    setEditable(false);

    form.setFieldsValue({
      [type]: (form.getFieldValue(type) as TypeSocialLink[]).map((item, i) => {
        if (i === name) {
          return {
            ...social,
            link: value,
          };
        } else {
          return item;
        }
      }),
    });
  };

  //
  const getRules: () => Rule[] = () => {
    switch (social.name) {
      case 'phone':
        return [{ required: true }];
      case 'email':
        return [{ type: 'email' }];
      default:
        return type === 'contacts' ? [] : [{ type: 'url' }];
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      [type]: (form.getFieldValue(type) as TypeSocialLink[]).map((item, i) => {
        if (i === name) {
          return {
            ...social,
            publish: published,
          };
        } else {
          return item;
        }
      }),
    });
  }, [published]);

  //
  return (
    <Row align="middle">
      <Col xs={24} lg={6} className="ant-form-item-label ant-form-item-label-left">
        <img src={socialLogos[social.name]} width={24} height={24} />
        <label
          style={{ margin: '0 8px' }}
          className={classNames({
            'ant-form-item-required': social.name === 'phone',
            // social.name === 'phone' || social.name === 'whatsapp' || social.name === 'telegram',
          })}
        >
          {type !== 'socials'
            ? t(social.name.charAt(0).toUpperCase() + social.name.slice(1))
            : social.name.charAt(0).toUpperCase() + social.name.slice(1)}
        </label>
      </Col>
      <Col xs={24} lg={18}>
        <div
          style={{
            display: 'flex',
            alignItems: window.outerWidth > 575 ? 'center' : 'flex-start',
            flexDirection: window.outerWidth > 575 ? 'row' : 'column',
          }}
        >
          <Form.Item
            name={[name, 'name']}
            fieldKey={[fieldKey, 'name']}
            colon={false}
            label={''}
            hidden
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={[name, 'link']}
            fieldKey={[fieldKey, 'link']}
            colon={false}
            label={t(social.name.charAt(0).toUpperCase() + social.name.slice(1))}
            rules={getRules()}
            wrapperCol={{ span: 24 }}
            labelCol={{ hidden: true }}
            style={{
              margin: window.outerWidth > 575 ? '0 8px 0 0' : '10px 0 0',
              flex: 1,
              width: window.outerWidth > 575 ? 'auto' : '100%',
            }}
          >
            {social.name !== 'phone' ? (
              <input
                style={{
                  backgroundColor: published ? '#ffffff' : '#f5f5f5',
                  boxShadow: editable ? '0 0 0 2px rgb(0 98 243 / 20%)' : 'none',
                  border: editable ? '1px solid #0934CD' : '1px solid #E1E6F5',
                  color: published ? '#020737' : 'rgba(2, 7, 55, 0.5)',
                }}
                className={classNames([
                  'ant-input',
                  'ibb-profile-input',
                  { 'ibb-profile-input__active': published },
                ])}
                disabled={!editable}
                placeholder={t(changePlaceholder())}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            ) : (
              <PhoneInput
                country={'il'}
                preferredCountries={['il', 'ru']}
                value={value}
                inputProps={{
                  className: classNames([
                    'ant-input',
                    'ibb-profile-input',
                    { 'ibb-profile-input__active': published },
                  ]),
                  style: {
                    paddingLeft: 60,
                    paddingRight: 60,
                    backgroundColor: published ? '#ffffff' : '#f5f5f5',
                    boxShadow: editable ? '0 0 0 2px rgb(0 98 243 / 20%)' : 'none',
                    border: editable ? '1px solid #0934CD' : '1px solid #E1E6F5',
                    color: published ? '#020737' : 'rgba(2, 7, 55, 0.5)',
                  },
                }}
                placeholder="+972 054 987 65 43"
                disabled={!editable}
                onChange={(a, b, c, formattedValue) => setValue(formattedValue)}
              />
            )}
            <div
              style={{
                position: 'absolute',
                top: 3,
                right: 10,
              }}
            >
              {editable ? (
                <>
                  <Button
                    type="text"
                    size="small"
                    icon={<img src={input_save} className="anticon" style={{ margin: 0 }} />}
                    onClick={() => saveValue(value)}
                  />

                  <Button
                    type="text"
                    size="small"
                    onClick={restoreValue}
                    icon={<img src={input_cancel} className="anticon" style={{ margin: 0 }} />}
                  />
                </>
              ) : (
                <Button
                  type="text"
                  size="small"
                  onClick={() => setEditable(true)}
                  icon={<img src={pensil_grey} className="anticon" />}
                />
              )}
            </div>
          </Form.Item>

          <Row
            style={{
              margin: window.outerWidth > 575 ? '10px 0' : '10px 0 25px',
              width: window.outerWidth > 575 ? 'auto' : '100%',
            }}
          >
            <Space align="center">
              <Button
                type="text"
                size="small"
                icon={
                  <img
                    src={published ? visible : unvisible}
                    className="anticon"
                    style={{ margin: 0 }}
                  />
                }
                onClick={() => setPublished((state) => !state)}
              />

              <Button
                type="text"
                size="small"
                icon={<img src={trash} className="anticon" />}
                onClick={() => handleDelete(name)}
              />
            </Space>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default UserSocialLink;
