import React from 'react';
import { historyActionsType } from './actions';
import { StateType } from 'typesafe-actions';

/**
 * Initial state
 */
const INITIAL_HISTORY_STATE: React.ReactNode[] = [];

/**
 * Root reducer
 *
 * @param state prev state
 * @param action current action
 */
export function historyReducer(history = INITIAL_HISTORY_STATE, action: historyActionsType) {
  switch (action.type) {
    case 'ADD':
      return [
        ...history.filter((item: any) => item.props.children?.type?.length !== 0),
        action.payload,
      ];
    case 'CLEAR':
      return [];
    default:
      return history;
  }
}

export type historyReducerType = StateType<typeof historyReducer>;
