import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StoreType } from '../../_store';
import { useDispatch, useSelector } from 'react-redux';
import letter from '../../_style/ico/letter.svg';

import hamburgerIcon from '../../_style/ico/hamburger.svg';
import { Image, Row, Button, Badge, Col, Drawer } from 'antd';

import { LanguagesSelector, CurrencySelector, MainMenu, ReferalLink, UserMenu } from '..';
import logo from '../../_style/img/logo.svg';
import { useEffect, useState } from 'react';
import { API } from '../../_helper';

const HeaderUI = () => {
  //
  const { t } = useTranslation();

  //
  const dispatch = useDispatch();

  // считываем из redux
  const user = useSelector((state: StoreType) => state.user);

  //
  const [drawerVisible, setDrawerVisible] = useState(false);

  //
  useEffect(() => {
    setInterval(async () => {
      const profile = await API('account');
      if (!profile.error) {
        if (profile.newMessages !== user.newMessages) {
          dispatch({
            type: 'SET_USER',
            payload: {
              newMessages: profile.newMessages,
            },
          });
        }
      }
    }, 1000 * 10);
  }, []);

  /**
   *
   */
  return (
    <Row align={'middle'} style={{ width: '100%' }}>
      {window.outerWidth > 575 && (
        <ReferalLink
          shareTitle={'Referral link'}
          shareUrl={`https://infobbook.com?r=${user.slug}`}
          copyText={`https://infobbook.com?r=${user.slug}`}
        />
      )}

      {window.outerWidth <= 575 && (
        <Col>
          <Button
            icon={<img src={hamburgerIcon} />}
            style={{ padding: 0, border: 'none', boxShadow: 'none' }}
            onClick={() => setDrawerVisible(true)}
          />
          {/* <Link to="/">
            <img src={logo} alt="ImfoBBook logo" style={{ width: 70, margin: '0 25px' }} />
          </Link> */}
          <Button
            type="link"
            onClick={() => {
              window.location.href = process.env.REACT_APP_CURRENT_DOMAIN + '/';
            }}
          >
            <img src={logo} alt="ImfoBBook logo" style={{ width: 70, margin: '0 25px' }} />
          </Button>
          <Drawer
            placement="left"
            closable={false}
            onClose={() => setDrawerVisible(false)}
            visible={drawerVisible}
            bodyStyle={{ padding: 0 }}
            maskStyle={{
              backgroundColor: 'rgba(2,7,37,0.7)',
              backgroundImage: `${''}url("data:image/svg+xml,%3Csvg width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M10.0745 24.6591L25.1594 9.57411C25.678 9.05557 25.5998 8.12889 24.9857 7.51482C24.3717 6.90075 23.445 6.8226 22.9264 7.34114L7.84149 22.4261C7.32295 22.9446 7.4011 23.8713 8.01517 24.4854C8.62923 25.0994 9.55591 25.1776 10.0745 24.6591Z' fill='white' /%3E%3Cpath d='M22.9255 24.6591L7.84059 9.57411C7.32205 9.05557 7.4002 8.12889 8.01427 7.51482C8.62834 6.90075 9.55502 6.8226 10.0736 7.34114L25.1585 22.4261C25.6771 22.9446 25.5989 23.8713 24.9848 24.4854C24.3708 25.0994 23.4441 25.1776 22.9255 24.6591Z' fill='white' /%3E%3C/svg%3E")`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '260px 16px',
            }}
            width={250}
          >
            <MainMenu collapsed={false} selectCallback={() => setDrawerVisible(false)} />
          </Drawer>
        </Col>
      )}

      <Col flex={1}>
        {/* {window.outerWidth > 1200 && (
          <Space size={0} align="center" style={{ width: '100%', justifyContent: 'center' }}>
            <FreeTimer />
          </Space>
        )} */}
      </Col>

      {window.outerWidth > 1200 && (
        <>
          <Col>
            <LanguagesSelector />
          </Col>

          <Col>
            <CurrencySelector />
          </Col>
        </>
      )}

      <Col style={{ margin: window.outerWidth > 575 ? '0 25px' : '0 15px', display: 'flex' }}>
        <Link to="/chat">
          <Badge
            count={user.newMessages}
            style={{
              display: 'block',
              marginTop: 19,
              borderRadius: 5,
              fontSize: 10,
              padding: '0 3px',
              height: 16,
              lineHeight: '16px',
            }}
          >
            {/* <MailOutlined /> */}
            <Image src={letter} preview={false} alt="letter" />
          </Badge>
        </Link>
      </Col>

      <Col>
        <UserMenu />
      </Col>
    </Row>
  );
};

export default HeaderUI;
