import telegram from './ico/contacts/telegram.svg';
import facebook from './ico/contacts/facebook.svg';
import instagram from './ico/contacts/instagram.svg';
import phone from './ico/contacts/phone.svg';
import email from './ico/contacts/email.svg';
import messenger from './ico/contacts/messenger.svg';
import skype from './ico/contacts/skype.svg';
import linkedin from './ico/contacts/linkedin.svg';
import twitter from './ico/contacts/twitter.svg';
import ok from './ico/contacts/ok.svg';
import whatsapp from './ico/contacts/whatsapp.svg';
import viber from './ico/contacts/viber.svg';

export const socials = {
  telegram,
  facebook,
  instagram,
  phone,
  email,
  messenger,
  skype,
  linkedin,
  twitter,
  ok,
  whatsapp,
  viber,
};
