import moment from 'moment';
import 'moment/min/locales.min';

export const asCurrency = (value: number, locale: string, currency: string) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(value);
};

export const asDate = (date: Date, locale: string) => {
  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const asDateTime = (date: Date, locale: string) => {
  return date.toLocaleTimeString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const asTime = (date: Date, locale: string) => {
  return date.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' });
};

export const asRelativeTime = (date: Date, locale: string) => {
  moment.locale(locale);
  return moment(date).fromNow();
};

const units = ['byte', 'kilobyte', 'megabyte', 'gigabyte', 'terabyte', 'petabyte'];
export const asSize = (size: number, locale: string) => {
  let l = 0;
  let n = size;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return new Intl.NumberFormat(locale, {
    style: 'unit',
    unit: units[l],
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(n);
};

export const minToCount = (minutes: number) => {
  if (minutes % (60 * 24 * 7) === 0) {
    return {
      count: minutes / (60 * 24 * 7),
      interval: 'w',
    };
  }
  if (minutes % (60 * 24) === 0) {
    return {
      count: minutes / (60 * 24),
      interval: 'd',
    };
  }
  if (minutes % 60 === 0) {
    return {
      count: minutes / 60,
      interval: 'h',
    };
  }
  return {
    count: minutes,
    interval: 'm',
  };
};

export const countToMin = (count: number, interval: string) => {
  switch (interval) {
    case 'w':
      return count * 60 * 24 * 7;
    case 'd':
      return count * 60 * 24;
    case 'h':
      return count * 60;
    default:
      return count;
  }
};
