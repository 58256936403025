import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import defaultPoster from '../../_style/ico/video/video_lg.svg';
import { Modal } from 'antd';

type VideoProps = {
  source: string | null;
  type?: string;
};

/** */
const VideoPlayer: React.FC<VideoProps> = ({ source, type = 'video/mp4' }) => {
  //
  const [play, setPlay] = useState(false);
  const [visible, setVisible] = useState(false);

  //
  const player = useRef<HTMLVideoElement>(null);

  //
  useEffect(() => {
    if (source && player.current) {
      if (play) {
        player.current.play();
      } else {
        player.current.pause();
      }
    }
  }, [play]);

  //
  return (
    <>
      {source && <div className={classNames(['ibb-video-plaer', { active: play }])} />}
      <video
        // ref={player}
        style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 8 }}
        controls={false}
        preload="auto"
        poster={!source ? defaultPoster : undefined}
        loop={true}
        onClick={() => {
          setPlay(true);
          setVisible(true);
        }}
      >
        {source && <source src={source + '?t=0.01'} type={type}></source>}
      </video>

      {source && (
        <Modal
          visible={visible}
          footer={false}
          closable={false}
          onCancel={() => {
            setPlay(false);
            setVisible(false);
          }}
        >
          {/* <div style={{ width: '50vw' }}> */}
          <video
            ref={player}
            style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: 8 }}
            controls={false}
            preload="auto"
            poster={!source ? defaultPoster : undefined}
            loop={true}
            onClick={() => setPlay((s) => !s)}
          >
            {source && <source src={source + '?t=0.01'} type={type}></source>}
          </video>
          {/* </div> */}
        </Modal>
      )}
    </>
  );
};

export default VideoPlayer;
