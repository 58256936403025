import { userActionsType } from './actions';
import { StateType } from 'typesafe-actions';
import avatar from '../../_style/ico/avatar.svg';

/**
 * Данные по пользователю
 */

export type TypeSocialLink = {
  name: string;
  link: string;
  publish: boolean;
};

export type UserState = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  secondEmail?: string;
  imageUrl?: string | null;
  phone: string;
  createdAt?: number;
  videoUrl: string | null;
  videoFileName?: string | null;
  videoAvatar: string | null;
  business: number[];
  description: string;
  socials: TypeSocialLink[];
  contacts: TypeSocialLink[];
  ballance: number;
  filesUsage: number;
  newMessages: number;
  public: number;

  subscription: number;

  collapsedMenu: boolean;

  googleAccess: boolean;
  slug: string;
};

/**
 * Initial state
 */
const INITIAL_USER_STATE: UserState = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  description: '',
  imageUrl: avatar,
  // tariffId: -1,
  createdAt: 1621841998,
  business: [],
  ballance: 0,
  filesUsage: 0,
  newMessages: 0,
  public: 1,
  socials: [],
  contacts: [],
  videoUrl: null,
  videoAvatar: null,

  subscription: 0,

  collapsedMenu: !!localStorage.getItem('menu'),

  googleAccess: false,
  slug: '',
};

/**
 * Root reducer
 *
 * @param state prev state
 * @param action current action
 */
export function userReducer(user = INITIAL_USER_STATE, action: userActionsType) {
  switch (action.type) {
    case 'SET_USER':
      // console.log('userReducer (SET_USER): ', action.payload);
      return { ...user, ...action.payload };
    case 'CLEAR_USER':
      // console.log('userReducer: CLEAR_USER', INITIAL_USER_STATE);
      return INITIAL_USER_STATE;
    default:
      return user;
  }
}

export type userReducerType = StateType<typeof userReducer>;
