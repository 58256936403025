import { Button, Col, Popover, Typography, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  MailruIcon,
  MailruShareButton,
  OKIcon,
  OKShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  VKIcon,
  VKShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import copyIcon from '../../_style/ico/copy.svg';
import shareIcon from '../../_style/ico/share.svg';

const { Paragraph, Text } = Typography;

interface ReferalLinkProps {
  showText?: boolean;
  shareUrl?: string;
  shareTitle?: string;
  copyText?: string;
}

const shareUrlDefault = location.href;
const shareTitleDefault = 'InfoBBook';

const ReferalLink: React.FC<ReferalLinkProps> = ({
  showText = true,
  shareUrl = shareUrlDefault,
  shareTitle = shareTitleDefault,
  copyText = 'https://reflink.infobbokwewewe.com',
}) => {
  //
  const { t } = useTranslation();

  return (
    <Col
      style={
        window.outerWidth > 575
          ? showText
            ? { display: 'flex', alignItems: 'center' }
            : { padding: 0, display: 'flex', alignItems: 'center' }
          : {
              padding: 0,
            }
      }
    >
      {showText && <Text type="secondary">{t(shareTitle)}</Text>}

      <Input.Group
        compact
        style={{
          border: '1px solid #e1e6f5',
          borderRadius: 5,
          backgroundColor: 'white',
          maxWidth: !showText ? 340 : window.outerWidth > 575 ? 230 : 'none',
          margin: window.outerWidth > 575 ? (showText ? '0 10px' : 0) : '10px 0',
        }}
      >
        <Input
          disabled={true}
          value={copyText}
          style={{
            width: 'calc(100% - 80px)',
            border: 'none',
            height: 38,
            backgroundColor: '#ffffff',
          }}
        />
        <Paragraph
          copyable={{
            text: copyText,
            icon: [
              <Button
                style={{ margin: -1, borderRadius: 0 }}
                icon={<img src={copyIcon} className="anticon" />}
              />,
              <Button
                style={{ margin: -1, borderRadius: 0 }}
                icon={<img src={copyIcon} className="anticon" />}
              />,
            ],
          }}
          style={{ margin: 0 }}
        />
        <Popover
          placement="bottom"
          content={
            <>
              <FacebookShareButton url={shareUrl} quote={shareTitle}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              &nbsp;&nbsp;
              <WhatsappShareButton url={shareUrl} title={shareTitle}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              &nbsp;&nbsp;
              <LinkedinShareButton url={shareUrl} title={shareTitle}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              &nbsp;&nbsp;
              <TelegramShareButton url={shareUrl} title={shareTitle}>
                <TelegramIcon size={32} round />
              </TelegramShareButton>
              &nbsp;&nbsp;
              <TwitterShareButton url={shareUrl} title={shareTitle}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              &nbsp;&nbsp;
              <ViberShareButton url={shareUrl} title={shareTitle}>
                <ViberIcon size={32} round />
              </ViberShareButton>
              &nbsp;&nbsp;
              <VKShareButton url={shareUrl} title={shareTitle}>
                <VKIcon size={32} round />
              </VKShareButton>
              &nbsp;&nbsp;
              <MailruShareButton url={shareUrl} title={shareTitle}>
                <MailruIcon size={32} round />
              </MailruShareButton>
              &nbsp;&nbsp;
              <OKShareButton url={shareUrl} title={shareTitle}>
                <OKIcon size={32} round />
              </OKShareButton>
            </>
          }
          trigger="click"
        >
          <Button
            style={{
              margin: -1,
              border: 'none',
              background: 'none',
              minWidth: 40,
            }}
            icon={<img src={shareIcon} className="anticon" />}
          />
        </Popover>
      </Input.Group>

      {/* <div
        // size={0}
        style={{
          margin: window.outerWidth > 575 ? (showText ? '0 10px' : 0) : '10px 0',
          border: '1px solid #e1e6f5',
          borderRadius: 5,
          maxHeight: 40,
          // overflow: 'hidden',
          display: 'flex',
        }}
      >
        <Text
          className="referalLink"
          style={{
            maxWidth: !showText ? 260 : window.outerWidth > 575 ? 150 : 'none',
          }}
        >
          {copyText}
        </Text>
        <Paragraph
          copyable={{
            text: copyText,
            icon: [
              <Button style={{ margin: -1, borderRadius: 0 }} icon={<img src={copyIcon} />} />,
              <Button style={{ margin: -1, borderRadius: 0 }} icon={<img src={copyIcon} />} />,
            ],
          }}
          style={{ margin: 0 }}
        />

        <Popover
          placement="bottom"
          content={
            <>
              <FacebookShareButton url={shareUrl} quote={shareTitle}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              &nbsp;&nbsp;
              <WhatsappShareButton url={shareUrl} title={shareTitle}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              &nbsp;&nbsp;
              <LinkedinShareButton url={shareUrl} title={shareTitle}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              &nbsp;&nbsp;
              <TelegramShareButton url={shareUrl} title={shareTitle}>
                <TelegramIcon size={32} round />
              </TelegramShareButton>
              &nbsp;&nbsp;
              <TwitterShareButton url={shareUrl} title={shareTitle}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              &nbsp;&nbsp;
              <ViberShareButton url={shareUrl} title={shareTitle}>
                <ViberIcon size={32} round />
              </ViberShareButton>
              &nbsp;&nbsp;
              <VKShareButton url={shareUrl} title={shareTitle}>
                <VKIcon size={32} round />
              </VKShareButton>
              &nbsp;&nbsp;
              <MailruShareButton url={shareUrl} title={shareTitle}>
                <MailruIcon size={32} round />
              </MailruShareButton>
              &nbsp;&nbsp;
              <OKShareButton url={shareUrl} title={shareTitle}>
                <OKIcon size={32} round />
              </OKShareButton>
            </>
          }
          trigger="click"
        >
            <Button
              style={{
                margin: -1,
                border: 'none',
                background: 'none',
                minWidth: 40,
              }}
              icon={<img src={shareIcon} />}
            />
        </Popover>
      </div> */}
    </Col>
  );
};

export default ReferalLink;
