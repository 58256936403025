import { ChangeEvent, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Stripe, StripeCardElement, StripeElements } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { API } from '../../_helper';
import { UserState } from '../../_store/user/reducers';
import { Button, Form, Space, Typography } from 'antd';
const { Title, Text } = Typography;

interface TypeProps {
  user: UserState;
  setShowPayment: (value: boolean) => void;
  setShowPaymentSuccess: () => void;
}

const AddPaymentCardForm = ({ user, setShowPayment, setShowPaymentSuccess }: TypeProps) => {
  const stripe: Stripe | null = useStripe();
  const elements: StripeElements | null = useElements();

  const [isVisibleLoader, setIsVisibleLoader] = useState(false);

  const { t } = useTranslation();

  const handleSubmit = async () => {
    // Block native form submission.
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement: StripeCardElement | null = elements.getElement(CardElement);

    if (!cardElement) {
      return;
    }

    setIsVisibleLoader(true);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: user.email,
        name: user.firstName + ' ' + user.lastName,
        phone: user.phone,
        // address: {
        //     line1: order.buyer.address
        // },
      },
    });

    if (error) {
      console.error('[error]', error);
      setIsVisibleLoader(false);
      return;
    }

    if (!paymentMethod) {
      setIsVisibleLoader(false);
      return;
    }

    const token = await stripe.createToken(cardElement);
    // console.log('token', token);
    const tokenResponse = await API('account/payment-info', 'POST', token);

    setIsVisibleLoader(false);
    setShowPaymentSuccess();
    setShowPayment(false);

    cardElement.clear();
  };

  // form
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <Form
      size="middle"
      {...layout}
      name="basic"
      // initialValues={{
      //   amount: amount,
      // }}
      labelAlign={'left'}
      onFinish={handleSubmit}
    >
      <Title level={4}>Добавление новой платёжной карты:</Title>
      <div
        style={{
          border: '1px solid #E1E6F5',
          borderRadius: 5,
          width: '100%',
          padding: '0 10px',
          margin: '16px 0 24px',
        }}
      >
        <CardElement
          // onReady={(el) => el.focus()}
          // onFocus={fieldIsInFocus}
          // onBlur={fieldIsNotInFocus}
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: 'black',
                lineHeight: '50px',
                fontFamily: 'Assistant, sans-serif',
                '::placeholder': {
                  color: 'grey',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      </div>

      <Space style={{ width: '100%', justifyContent: 'center' }}>
        <Button type="primary" htmlType="submit" loading={isVisibleLoader}>
          {t('Add card')}
        </Button>
      </Space>
    </Form>
  );
};

export default AddPaymentCardForm;
