import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementLocale } from '@stripe/stripe-js';
import { Button, Modal, Typography, Form, Input, Result } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { API } from '../../_helper';
import { StoreType } from '../../_store';
import closeIcon from '../../_style/ico/close.svg';
import closeIconWhite from '../../_style/ico/close_white.svg';
import CheckoutForm from '../CheckoutForm';

const BlockedUI = () => {
  //
  const { t, i18n } = useTranslation();

  // считываем из redux
  const user = useSelector((state: StoreType) => state.user);

  //
  const dispatch = useDispatch();

  //
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [transparent, setTransparent] = useState(false);

  //
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

  //
  const onFinish = async (values: any) => {
    setLoading(true);

    // request POST
    const resp = await API('account', 'PUT', values);
    setLoading(false);
    if (!resp.error) {
      setShowUploadModal(false);
      dispatch({
        type: 'SET_USER',
        payload: {
          ...resp,
          tariffId: resp.tariff_id,
          firstName: resp.first_name,
          lastName: resp.last_name,
        },
      });
    }
  };

  useEffect(() => {
    if (showSuccessModal) {
      setTimeout(() => {
        setShowSuccessModal(false);
      }, 5000);
    }
  }, [showSuccessModal]);

  return (
    <>
      {user.subscription === 0 && (
        <div
          style={{
            position: 'fixed',
            background: transparent ? 'transparent' : 'rgba(0,0,0,0.5)',
            top: transparent ? 65 : 0,
            left: user.collapsedMenu ? 63 : 249,
            right: 0,
            height: '100%',
            zIndex: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            transition: user.collapsedMenu ? 'all 0.2s 0s' : 'all 0.2s 0.1s',
          }}
          onClick={() => {
            if (!user.email) {
              setShowUploadModal(true);
            }
            setTransparent(false);
          }}
        >
          {!transparent && (
            <div className="ibb__form" onClick={(e) => e.stopPropagation()}>
              <Button
                icon={<img src={closeIconWhite} />}
                type="link"
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  zIndex: 10,
                }}
                onClick={() => setTransparent(true)}
              />
              <div className="ibb__form-title">
                <span>1500$&nbsp;{t('in year')}</span>&nbsp;<big>/</big>
                <b>
                  &nbsp;199<span>$</span>
                </b>
                <br />
                {t('One-time Lifetime Payment!')}
              </div>
              <div className="ibb__form-bottom">
                <div className="ibb__form-btn">
                  <button className="ibb__btn-new white" onClick={() => setShowPaymentModal(true)}>
                    <div>{t('Purchase')}</div>&nbsp;<span>{t('at a discount')}</span>
                  </button>
                  <svg
                    width="35"
                    height="24"
                    viewBox="0 0 35 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.9399 0V10.22H0V12.96H24.68V6.22L30.46 11.58L21.9399 20.09V23.97L34.4399 11.51L21.9399 0Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div>{t('30-day money-back guarantee')}</div>
              </div>
            </div>
          )}
        </div>
      )}

      {/*
       *** modal window
       */}
      <Modal
        visible={!!showUploadModal}
        onCancel={() => setShowUploadModal(false)}
        closeIcon={<img src={closeIcon} />}
        footer={[
          <Button key="back">{t('Cancel')}</Button>,
          <Button
            key="submit"
            type="primary"
            form="form_submit"
            htmlType="submit"
            loading={loading}
          >
            {t('Send')}
          </Button>,
        ]}
      >
        <Typography.Title level={3} style={{ paddingRight: 20 }}>
          {t('Заполните дополнительные данные для удобства общения')}
        </Typography.Title>

        <Form
          id="form_submit"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 16 }}
          name="basic"
          labelAlign={'left'}
          onFinish={onFinish}
          initialValues={user}
        >
          <Form.Item colon={false} label={t('Name')} name="firstName" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item colon={false} label={t('Last Name')} name="lastName">
            <Input />
          </Form.Item>

          <Form.Item
            colon={false}
            label={t('Email')}
            name="email"
            rules={[{ required: true, type: 'email' }]}
          >
            <Input type="email" />
          </Form.Item>
        </Form>
      </Modal>

      {/*
       *** modal window Subscription payment
       */}
      <Modal
        visible={showPaymentModal}
        onCancel={() => setShowPaymentModal(false)}
        closeIcon={<img src={closeIcon} />}
        footer={false}
        style={{ maxWidth: 450 }}
      >
        <Typography.Title
          level={3}
          style={{
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 16,
            marginBottom: 15,
          }}
        >
          {t('Forewer subscription fee')}
        </Typography.Title>

        <Elements
          stripe={stripePromise}
          key={i18n.language}
          options={{
            fonts: [
              {
                cssSrc: 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap',
              },
            ],
            locale: i18n.language as StripeElementLocale,
          }}
        >
          <CheckoutForm
            user={user}
            setShowPayment={(show) => {
              setShowPaymentModal(show);
              setShowSuccessModal(!show);
            }}
            subscription
          />
        </Elements>
      </Modal>

      {/*
       *** modal window payment success
       */}
      <Modal
        visible={showSuccessModal}
        // onCancel={() => setShowSuccessModal(false)}
        footer={false}
        closable={false}
        centered
      >
        <Result status="success" title={t('Payment made successful')} />
      </Modal>
    </>
  );
};

export default BlockedUI;
