import ReactDOM from 'react-dom';
import App from './App';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import ru from './translations/ru';
import en from './translations/en';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import store from './_store';

i18n.use(initReactI18next).init({
  lng: 'ru',
  resources: {
    ru,
    en,
  },
  fallbackLng: 'en',
});

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <CookiesProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </CookiesProvider>
  </I18nextProvider>,
  document.getElementById('root')
);
