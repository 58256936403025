import { Avatar, Badge, Button, Popover, Row } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreType } from '../../_store';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import defaultAvatar from '../../_style/ico/avatar.svg';

// Dropdown Menu
const optionsMenu = [
  {
    title: 'Profile',
    route: '/profile',
  },
  // {
  //   title: 'Payment information',
  //   route: '/profile/payment-info',
  // },
  // {
  //   title: 'Payment history',
  //   route: '/profile/payment-history',
  // },
  // {
  //   title: 'Finances',
  //   route: '/profile/wallet',
  // },
];

const UserMenu = () => {
  //
  const { t } = useTranslation();

  //
  const dispatch = useDispatch();

  // считываем из redux
  const user = useSelector((state: StoreType) => state.user);

  //
  const [, , removeCookie] = useCookies(['token']);

  const logoutUser = () => {
    //
    localStorage.removeItem('token');
    //
    removeCookie('token', {
      domain: process.env.REACT_APP_COOKIES_DOMAIN,
      path: '/',
    });
    if (process.env.REACT_APP_CURRENT_DOMAIN) {
      window.location.href = process.env.REACT_APP_CURRENT_DOMAIN;
    }
  };

  //
  const [opened, setOpened] = useState(false);

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      onVisibleChange={(value) => {
        setOpened(value);
      }}
      visible={opened}
      content={
        <>
          {optionsMenu.map((item, key) => (
            <Row key={key}>
              <Link
                style={{
                  color: '#020737',
                  padding: '12px 0',
                  fontSize: 14,
                  minWidth: 160,
                }}
                key={key}
                to={item.route}
                onClick={() => setOpened((state) => !state)}
                className="ibb-head__menu-link"
              >
                {t(item.title)}
              </Link>
            </Row>
          ))}

          <Row>
            <Button
              style={{ all: 'unset', display: 'flex', alignItems: 'center', paddingTop: 12 }}
              onClick={() => {
                dispatch({
                  type: 'CLEAR_USER',
                });
                logoutUser();
              }}
            >
              <i className="icon-logout" style={{ fontSize: 24 }} />
              <span style={{ padding: '0 10px', cursor: 'pointer' }}>{t('Logout')}</span>
            </Button>
          </Row>
        </>
      }
    >
      <Row align="middle" style={{ cursor: 'pointer' }}>
        <Badge dot status="success">
          <Avatar
            style={{
              backgroundColor: '#fff',
              verticalAlign: 'middle',
              border: '1px solid #E1E6F5',
              margin: '0 10px',
            }}
            size="large"
            src={
              <img
                src={user.imageUrl || defaultAvatar}
                style={{ objectPosition: 'center', objectFit: 'cover' }}
              />
            }
          />
        </Badge>
        {window.outerWidth > 575 && (
          <span style={{ maxWidth: 80 }}>{`${user.firstName} ${user.lastName}`}</span>
        )}
        <svg
          style={{
            transition: 'all 0.2s',
            transform: opened ? 'scaleY(-1)' : 'scaleY(1)',
            margin: window.outerWidth > 575 ? '0 0 0 20px' : 0,
          }}
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.12508 1.00002L6.00508 4.88002L9.88508 1.00002C10.2751 0.610016 10.9051 0.610016 11.2951 1.00002C11.6851 1.39002 11.6851 2.02002 11.2951 2.41002L6.70508 7.00002C6.31508 7.39002 5.68508 7.39002 5.29508 7.00002L0.705083 2.41002C0.517831 2.22318 0.412598 1.96953 0.412598 1.70502C0.412598 1.4405 0.517831 1.18685 0.705083 1.00002C1.09508 0.620016 1.73508 0.610016 2.12508 1.00002Z"
            fill={opened ? '#b7002c' : '#b7002c'}
          />
        </svg>
      </Row>
    </Popover>
  );
};

export default UserMenu;
