import { useState } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { Stripe, StripeCardNumberElement, StripeElements } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { API } from '../../_helper';
import { UserState } from '../../_store/user/reducers';
import { Button, Card, Divider, Form, Input, InputNumber, notification, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import emailIcon from '../../_style/ico/dog.svg';
import userIcon from '../../_style/ico/avatar.svg';

interface TypeProps {
  user: UserState;
  setShowPayment: (show: boolean) => void;
  subscription?: boolean;

  initialAmount?: number;
}

const currency = 'USD';

const stripeStyle = {
  base: {
    fontSize: '16px',
    color: '#020737',
    lineHeight: '40px',
    fontFamily: 'Assistant, sans-serif',
    '::placeholder': {
      color: 'rgba(2, 7, 55, 0.5)',
    },
  },
  invalid: {
    color: '#9e2146',
  },
};

const CheckoutForm = ({
  user,
  setShowPayment,
  subscription = false,
  initialAmount = 0,
}: TypeProps) => {
  const stripe: Stripe | null = useStripe();
  const elements: StripeElements | null = useElements();

  const [isVisibleLoader, setIsVisibleLoader] = useState(false);

  const { t } = useTranslation();

  //
  const dispatch = useDispatch();

  const handleSubmit = async (values: {
    amount: number;
    fname: string;
    lname: string;
    email: string;
  }) => {
    if (!stripe || !elements || !values.amount) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      notification.error({
        message:
          'Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.',
      });
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement: StripeCardNumberElement | null = elements.getElement(CardNumberElement);

    if (!cardElement) {
      notification.error({
        message: 'No Card element',
      });
      return;
    }
    setIsVisibleLoader(true);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: values.email,
        name: values.fname + ' ' + values.lname,
        phone: user.phone,
        // address: {
        //     line1: order.buyer.address
        // },
      },
    });

    if (error) {
      console.error('[error]', error);
      setIsVisibleLoader(false);
      return;
    }

    if (!paymentMethod) {
      setIsVisibleLoader(false);
      return;
    }

    // Otherwise send paymentMethod.id to your server (see Step 4)
    const response = await API('account/payment', 'POST', {
      amount: values.amount,
      paymentMethodId: paymentMethod.id,
      currency,
    });

    // Handle server response (see Step 4)
    // console.log('response', response);
    handleServerResponse(response, cardElement, values.amount);
  };

  const handleServerResponse = async (
    response: any,
    cardElement: StripeCardNumberElement | null,
    amount: number
  ) => {
    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setIsVisibleLoader(false);
      return;
    }

    if (!response) {
      // Show error from server on payment form
      // NOT SUCCESS
      setIsVisibleLoader(false);
      notification.error({
        message: 'The order was not completed. Please try again',
      });
    } else if (response.requiresAction) {
      // Use Stripe.js to handle the required card action
      const { error, paymentIntent } = await stripe.handleCardAction(
        response.paymentIntentClientSecret
      );

      if (error) {
        // Show error from Stripe.js in payment form
        setIsVisibleLoader(false);
        console.error('[error]', error);
        return;
      }

      if (!paymentIntent) {
        setIsVisibleLoader(false);
        return;
      }

      // The card action has been handled
      // The PaymentIntent can be confirmed again on the server
      const serverResponse = await API('account/payment', 'POST', {
        amount,
        paymentIntentId: paymentIntent.id,
        currency,
      });

      handleServerResponse(serverResponse, cardElement, amount);
    } else {
      if (response.error) {
        setIsVisibleLoader(false);
        notification.error({
          message: response.error,
        });
      }

      if (response.success) {
        if (subscription) {
          const sub = await API('account/subscribe', 'POST');

          if (sub.error) {
            setIsVisibleLoader(false);
            notification.error({
              message: sub.error,
            });
            return;
          }

          dispatch({
            type: 'SET_USER',
            payload: {
              ...sub,
              firstName: sub.first_name,
              lastName: sub.last_name,
            },
          });
        }

        setIsVisibleLoader(false);
        setShowPayment(false);

        // notification.success({
        //   message: t('Payment made successful'),
        // });

        if (response.newCard && cardElement) {
          const token = await stripe.createToken(cardElement);
          console.log('token', token);
          const tokenResponse = await API('account/payment-info', 'POST', token);
          console.log('tokenResponse', tokenResponse);
        }

        cardElement?.clear();
      }
    }
  };

  return (
    <Form
      size="middle"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      name="basic"
      initialValues={{
        amount: subscription ? 199 : initialAmount,

        fname: user.firstName,
        lname: user.lastName,
        email: user.email,
        phone: user.phone,
      }}
      labelAlign={'left'}
      onFinish={handleSubmit}
    >
      <Card
        style={{ backgroundColor: '#F8F5F3', marginBottom: 16, border: 'none' }}
        bodyStyle={{ padding: '10px 16px' }}
      >
        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
          <span
            style={{
              fontSize: 16,
            }}
          >
            {t('Summa')}:
          </span>
          <b
            style={{
              fontSize: 20,
            }}
          >
            {'$ 199'}
          </b>
        </Space>
      </Card>

      {/* <Divider /> */}
      <Form.Item
        colon={false}
        label={t('the top-up amount')}
        name="amount"
        rules={[{ required: true }]}
        hidden={subscription}
      >
        <InputNumber
          style={{ width: '100%' }}
          formatter={(value) => `$  ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          parser={(value) => `${value}`.replace(/$\s\s?|(\s*)/g, '')}
        />
      </Form.Item>

      <div
        style={{
          fontSize: 16,
          marginBottom: 16,
        }}
      >
        {t('Contact Information')}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Form.Item
          colon={false}
          label={t('Name')}
          name="fname"
          rules={[{ required: true }]}
          labelCol={{ hidden: true }}
          style={{ width: 'calc(50% - 12px)' }}
        >
          <Input
            placeholder={t('Name')}
            // size="large"
            prefix={<img src={userIcon} style={{ width: 20, height: 20, margin: '0 5px' }} />}
          />
        </Form.Item>
        <Form.Item
          colon={false}
          label={t('Last Name')}
          name="lname"
          rules={[{ required: true }]}
          labelCol={{ hidden: true }}
          style={{ width: 'calc(50% - 12px)' }}
        >
          <Input
            placeholder={t('Last Name')}
            // size="large"
            prefix={<img src={userIcon} style={{ width: 20, height: 20, margin: '0 5px' }} />}
          />
        </Form.Item>
      </div>
      <Form.Item
        colon={false}
        label={t('Email')}
        name="email"
        rules={[{ required: true }, { type: 'email' }]}
        labelCol={{ hidden: true }}
      >
        <Input
          placeholder={t('Email')}
          // size="large"
          prefix={<img src={emailIcon} style={{ width: 20, height: 20, margin: '0 5px' }} />}
        />
      </Form.Item>

      <Form.Item
        colon={false}
        label={t('Phone')}
        name="phone"
        rules={[{ required: true }]}
        labelCol={{ hidden: true }}
      >
        <PhoneInput
          country={'il'}
          preferredCountries={['il', 'ru']}
          inputProps={{
            className: 'ant-input',
            style: {
              paddingLeft: 60,
              paddingRight: 60,
            },
          }}
          placeholder="+972 054 987 65 43"
        />
      </Form.Item>

      <div
        style={{
          fontSize: 16,
          marginBottom: 16,
          marginTop: 32,
        }}
      >
        {t('Payment Information')}
      </div>

      <div
        style={{
          border: '1px solid #E1E6F5',
          borderRadius: 5,
          width: '100%',
          padding: '0 10px',
          margin: '0 0 16px',
        }}
      >
        <CardNumberElement
          options={{
            showIcon: true,
            iconStyle: 'solid',
            style: stripeStyle,
          }}
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            border: '1px solid #E1E6F5',
            borderRadius: 5,
            padding: '0 10px',
            margin: '0 0 16px',
            width: 'calc(50% - 12px)',
          }}
        >
          <CardExpiryElement
            options={{
              style: stripeStyle,
            }}
          />
        </div>

        <div
          style={{
            border: '1px solid #E1E6F5',
            borderRadius: 5,
            padding: '0 10px',
            margin: '0 0 16px',
            width: 'calc(50% - 12px)',
          }}
        >
          <CardCvcElement
            options={{
              style: stripeStyle,
            }}
          />
        </div>
      </div>

      <Form.Item
        colon={false}
        label={t('Cardholder Name')}
        name="name"
        rules={[{ required: true }]}
        labelCol={{ hidden: true }}
      >
        <Input
          placeholder={t('Cardholder Name')}
          // size="large"
          prefix={<img src={userIcon} style={{ width: 20, height: 20, margin: '0 5px' }} />}
        />
      </Form.Item>

      <Button size="large" type="primary" htmlType="submit" block loading={isVisibleLoader}>
        {t(subscription ? 'Purchase' : 'Create payment')}
      </Button>

      <Space
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 16,
          color: '#8492A6',
        }}
      >
        <svg
          width="12"
          height="14"
          viewBox="0 0 12 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.99984 4.66667H9.33317V3.33333C9.33317 1.49333 7.83984 0 5.99984 0C4.15984 0 2.6665 1.49333 2.6665 3.33333V4.66667H1.99984C1.2665 4.66667 0.666504 5.26667 0.666504 6V12.6667C0.666504 13.4 1.2665 14 1.99984 14H9.99984C10.7332 14 11.3332 13.4 11.3332 12.6667V6C11.3332 5.26667 10.7332 4.66667 9.99984 4.66667ZM5.99984 10.6667C5.2665 10.6667 4.6665 10.0667 4.6665 9.33333C4.6665 8.6 5.2665 8 5.99984 8C6.73317 8 7.33317 8.6 7.33317 9.33333C7.33317 10.0667 6.73317 10.6667 5.99984 10.6667ZM3.99984 3.33333V4.66667H7.99984V3.33333C7.99984 2.22667 7.1065 1.33333 5.99984 1.33333C4.89317 1.33333 3.99984 2.22667 3.99984 3.33333Z"
            fill="#C3CAD9"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.99984 4.66667H9.33317V3.33333C9.33317 1.49333 7.83984 0 5.99984 0C4.15984 0 2.6665 1.49333 2.6665 3.33333V4.66667H1.99984C1.2665 4.66667 0.666504 5.26667 0.666504 6V12.6667C0.666504 13.4 1.2665 14 1.99984 14H9.99984C10.7332 14 11.3332 13.4 11.3332 12.6667V6C11.3332 5.26667 10.7332 4.66667 9.99984 4.66667ZM5.99984 10.6667C5.2665 10.6667 4.6665 10.0667 4.6665 9.33333C4.6665 8.6 5.2665 8 5.99984 8C6.73317 8 7.33317 8.6 7.33317 9.33333C7.33317 10.0667 6.73317 10.6667 5.99984 10.6667ZM3.99984 3.33333V4.66667H7.99984V3.33333C7.99984 2.22667 7.1065 1.33333 5.99984 1.33333C4.89317 1.33333 3.99984 2.22667 3.99984 3.33333Z"
            fill="#B5BFD6"
          />
        </svg>
        Все данные сохраняются системой
        <a
          target="_blank"
          rel="noreferrer"
          href="https://stripe.com/"
          style={{
            color: '#8492A6',
            textDecoration: 'underline',
          }}
        >
          Stripe
        </a>
      </Space>
    </Form>
  );
};

export default CheckoutForm;
