import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Divider, Menu, Row, Space } from 'antd';
import logo from '../../_style/img/logo.svg';
import infobbok from '../../_style/img/infobbok.svg';
import { CurrencySelector, FreeTimer, LanguagesSelector, ReferalLink } from '..';
import { useSelector } from 'react-redux';
import { StoreType } from '../../_store';

const { SubMenu } = Menu;

interface TypeItemMenu {
  title: string;
  route: string;
  icon?: JSX.Element;
  items?: {
    title: string;
    route: string;
  }[];
}

/** */
interface TypeProps {
  collapsed: boolean;
  selectCallback?: () => void;
}

const MainMenu: React.FC<TypeProps> = ({
  collapsed,
  selectCallback = () => {
    return '';
  },
}) => {
  //
  const { t } = useTranslation();

  // считываем из redux
  const user = useSelector((state: StoreType) => state.user);

  //
  const history = useHistory();
  const location = useLocation();

  // switcher
  const [palitra, setPalitra] = useState({
    theme: 'dark',
  });

  const [tik, setTik] = useState(0);

  // switcher
  const changeTheme = (value: any) => {
    setPalitra({ theme: value ? 'dark' : 'light' });
  };

  const itemsMenu: TypeItemMenu[] = [
    // { title: 'Business pages', route: '/', icon: <i className="icon-main" /> },
    {
      title: 'Business pages',
      route: '/',
      icon: <i className="icon-pages" />,
    },
    { title: 'Contacts', route: '/leads', icon: <i className="icon-leads" /> },
    { title: 'Business calendar', route: '/calendar', icon: <i className="icon-calendar" /> },
    { title: 'Conferences', route: '/webinars', icon: <i className="icon-webinars" /> },
    { title: 'Business instruments', route: '/files', icon: <i className="icon-files" /> },
    {
      title: 'Finances',
      route: '/profile/wallet',
      icon: <i className="icon-wallet" />,
      // items: [
      //   { title: 'Wallet', route: '/profile/wallet' },
      //   { title: 'Profile', route: '/profile' },
      //   { title: 'Tariffs', route: '/tariffs' },
      //   { title: 'Payment history', route: '/profile/payment-history' },
      //   {
      //     title: 'Payment information',
      //     route: '/profile/payment-info',
      //   },
      // ],
    },
    {
      title: 'Profile',
      route: '/profile',
      icon: <i className="icon-profile" />,
    },
    // { title: 'Tariffs', route: '/tariffs', icon: <i className="icon-tariffs" /> },
    { title: 'Infobbook Users', route: '/users', icon: <i className="icon-people" /> },
    { title: 'Charity', route: '/charity', icon: <i className="icon-favorite" /> },
    { title: 'Promotion', route: '/promo', icon: <i className="icon-promo" /> },
    // { title: 'How it works', route: '/how-it-works', icon: <i className="icon-question" /> },
  ];

  //
  const profilePublic = (item: TypeItemMenu) => {
    if (item.title === 'Profile') {
      return false;
    } else if (user.contacts.length > 0 || user.subscription === 0) {
      return false;
    }
    return true;
  };

  //
  const renderMenuItem = (item: TypeItemMenu) => (
    <Menu.Item key={item.route} icon={item.icon} disabled={profilePublic(item)}>
      <Link className="ibb-menu__element" to={item.route} onClick={selectCallback}>
        {t(item.title)}
      </Link>
    </Menu.Item>
  );
  //
  const renderMenuItems = (items: TypeItemMenu[]) => {
    return items.map((item, key) => {
      if (!item.items) {
        return renderMenuItem(item);
      } else {
        // rootSubmenuKeys.push(`sub${key}`);
        return (
          <SubMenu key={`sub${key}`} icon={item.icon} title={<b>{t(item.title)}</b>}>
            {item.items.map((i) => {
              return renderMenuItem(i);
            })}
          </SubMenu>
        );
      }
    });
  };

  // Чтобы не залипал активный элемент меню при переходе по Routs
  useEffect(() => {
    setTik((s) => s + 1);
  }, [location.pathname]);

  /**
   *
   */
  return (
    <>
      <Space
        size={0}
        align="center"
        style={{
          width: '100%',
          minHeight: 64,
          justifyContent: 'center',
        }}
      >
        {/* <Link to="/">
          <img
            src={logo}
            alt="ImfoBBook logo"
            style={{ width: collapsed ? 54 : 70, transition: 'all 0.3s' }}
          />
          <img
            src={infobbok}
            alt="InfoBBook title"
            style={{ width: collapsed ? 0 : 115, transition: collapsed ? 'all 0.3s' : 'none' }}
          />
        </Link> */}
        <Button
          type="link"
          onClick={() => {
            window.location.href = process.env.REACT_APP_CURRENT_DOMAIN + '/';
          }}
          style={{ padding: 0 }}
        >
          <img
            src={logo}
            alt="ImfoBBook logo"
            style={{ width: collapsed ? 54 : 70, transition: 'all 0.3s' }}
          />
          {!collapsed && (
            <img
              src={infobbok}
              alt="InfoBBook title"
              style={{ width: collapsed ? 0 : 115, transition: collapsed ? 'all 0.3s' : 'none' }}
            />
          )}
        </Button>
      </Space>

      <Divider style={{ margin: '0 0 4px 0', borderColor: '#E1E6F5', opacity: 0.2 }} />

      {window.outerWidth <= 575 && (
        <>
          <FreeTimer />
          <div style={{ padding: '10px 20px 0' }}>
            <ReferalLink
              shareTitle={t('Реферальная ссылка на проект InfoBBook')}
              shareUrl={`https://infobbook.com?r=${user.slug}`}
              copyText={`https://infobbook.com?r=${user.slug}`}
            />
          </div>
        </>
      )}

      <Menu
        key={tik}
        style={{
          border: 'none',
        }}
        mode="inline"
        defaultSelectedKeys={[history.location.pathname]}
        theme={'dark'}
      >
        {renderMenuItems(itemsMenu)}
      </Menu>

      {window.outerWidth <= 575 && (
        <Row style={{ margin: '10px 0 20px' }}>
          <Col>
            <LanguagesSelector />
          </Col>

          <Col>
            <CurrencySelector />
          </Col>
        </Row>
      )}
      {/* <Switch
        checked={palitra.theme === 'dark'}
        onChange={changeTheme}
        checkedChildren="Dark"
        unCheckedChildren="Light"
      />{' '}
      <span>{palitra.theme === 'dark' ? 'Тёмная тема' : 'Светлая тема'}</span> */}
    </>
  );
};

export default MainMenu;
