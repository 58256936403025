import { Badge, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StoreType } from '../../_store';

const FreeTimer = () => {
  //
  const { t } = useTranslation();

  // считываем из redux
  const user = useSelector((state: StoreType) => state.user);

  // пробный период
  const timeIsOver = user.createdAt ? user.createdAt + 30 * 24 * 60 * 60 : 0;
  const today = new Date();
  const days_left = Math.floor(
    ((timeIsOver - (today.getTime() / 1000 - today.getTimezoneOffset() * 60)) / (60 * 60 * 24)) % 30
  );

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#F2F9FF',
        color: '#020737',
        padding: window.outerWidth > 1200 ? '10px 10px' : '10px 20px',
        fontWeight: 700,
        fontSize: '12px',
      }}
    >
      <Space>
        {t('left', { count: days_left })}
        <Badge count={days_left} />
        {t('days', { count: days_left })}
      </Space>
    </div>
  );
};

export default FreeTimer;
